.App-header {
  display: flex;
  color: white;
  padding: 25px;
  height: 300px;
  /* background-color: rgba(138, 45, 19, 1); */
  background: linear-gradient(#9198e5, rgba(33,80,56,1));
  align-items: center;
  justify-content: center;
}

.app-content {
  margin-top: 65px;
}

.app-body {
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.cards-body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: rgba(249, 251, 254, 1);
  align-items: center;
}
.app-title {
  font-size: 44px;
  font-weight: 800;
}
.app-body-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
}
.app-title-location {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 22px;
  margin-top: 25px;
  background-color: rgba(6, 40, 197);
  width: fit-content;
  padding: 15px;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 60%);
  border-radius: 24px;
}
.service-cards {
  display: flex;
  padding: 15px;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgba(200, 200, 200, 1);
  margin-bottom: 25px;
}

.copy-container {
  line-height: 1.8em;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(200, 200, 200, 1);
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 60%);
  margin-bottom: 25px;
  margin-top: 25px;
}
.header-left {
  display: flex;
  padding-left: 25px;
  flex-direction: column;
}
.pad {
  padding-top: 25px;
}
.header-right {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}
.size-pics {
  height: 600px;
  width: 500px;
}
.caro-container {
  display: flex;
  width: 80vw;
}

@keyframes slidein {
  from {
    width: 0px;
    opacity: 0;
    font-size: 1px;
  }

  to {
    width: 300px;
    opacity: 1;
    font-size: 16px;
  }
}

.help-flyout {
  color: white;
  font-weight: 700;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 25px;
  padding: 25px;
  background-color: rgba(6, 40, 197);
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  transition: 0.3s;
  animation: slidein 0.4s ease-in;
  z-index: 10;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 60%);
}
.dflex-right {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.m-top-25 {
  margin-top: 25px;
}
