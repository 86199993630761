.header {
  width: 100vw;
  position: fixed;
  top: 0;
  height: 65px;
  transition: all 0.5s ease;
  font-family: Avenir;
  background-color: white;
  z-index: 300;
}
.header-container {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  font-size: 16px;
  padding-left: 25px;
}
.tmp-class {
  height: 30px;
}
.header-text {
  display: flex;
  height: 100%;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  flex-grow: 0.5;
}
.header-items {
  display: flex;
  height: 100%;
  width: 100vw;
  align-items: center;
}
.header-subtabs {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
}
.header-subtabs:hover {
  color: rgba(6, 69, 173, 1);
  border-bottom: 3px solid blue;
  cursor: pointer;
}
.subtab-wrap {
  display: flex;
  flex-grow: 0.5;
}
.overview-header {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 50vh;
  align-items: center;
  justify-content: center;
  color: white;
}

/* Position and sizing of burger button */
.bm-burger-button {
  margin-top: 3px;
  position: fixed;
  width: 32px;
  height: 22px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
  height: 3px !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgba(6, 69, 173, 1);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 14px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.footer {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  padding-left: 25px;
}
.contact-footer {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.call-button {
  border: 1px solid grey;
  padding: 2px;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 4px;
  font-weight: 800;
  background-color: rgba(6, 40, 197);
}
.m-right-25 {
  margin-right: 25px;
}
.dflex {
  display: flex;
  align-items: center;
}
.title-footer {
  display: flex;
  font-size: 18px;
  font-weight: bold;
}
.footer-row {
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
}